<template>
  <PageWithLayout
    bgColor="#F3F1F1"
    :isBackBtn="true"
    :isBackWebEnd="true"
    :isTitle="true"
    :title="$poData.child_wallet_main_title.format(viewModel.model.user.name)"
    :isRightTextMenu="true"
    :rightTextMenu="$poData.child_wallet_main_setting"
    :isBodyFull="true"
    :leftRightPadding="20"
    :bottomPadding="48"
    @onClickRightMenu="pageMove('cWalletSet')">
    <WalletHead
      :title="$poData.child_wallet_main_balance"
      :amount="viewModel.model.wallet_amount"
      :money_amount="viewModel.model.money_amount"
      :point_amount="viewModel.model.point_amount">
      <template v-slot:inFold>
        <Button
          v-if="has_return_btn"
          class="btn_return"
          btnStyle="primary"
          :text="$poData.child_wallet_main_send_money_btn"
          @onClickBtn="pageMove('sendPocketMoneyReturn')" />
      </template>
    </WalletHead>
    <CategoryStatistic
      :paymentCategory="viewModel.model.paymentCategory"
      routeName="ChildTransListV2"/>
    <div class="box_translist">
      <ButtonRouter
      class="btn_router_transList"
      :text="$poData.child_details_heading_2"
      :isArrowRight="viewModel.model.has_transaction"
      :routeName="viewModel.model.has_transaction ? 'ChildTransListV2' : ''"
      :routeQuery="{ has_return_btn : has_return_btn }">
      <!-- <template v-if="viewModel.model.has_transaction" v-slot:btn_right>
        <span class="txt_router">{{ $poData.child_wallet_main_show_all }}</span>
      </template> -->
    </ButtonRouter>
    <template v-if="viewModel.model.has_transaction">
      <ListItem
        v-for="(itemData, index) in viewModel.model.wallet_transaction_list"
        :key="`transItem${index}`"
        :padding="17"
        :thumbNail="{
          size: 40,
          url : itemData.profile_image_url
        }"
        :title="itemData.title"
        :leftSubText="itemData.message || itemData.memo"
        :leftSubTextColor="itemData.message ? 'primary' : 'gray'"
        :leftBadge="{
          value : itemData.label_display_name,
          type : 'sub1'
        }"
        :rightSubText="itemData.amount | convertNumberToComma(false,'0','원')"
        :isRightSubTextColor="itemData.amount >= 0 ? 'red' : ''"
        @onClickItem="viewModel.onClickDetail(itemData.transaction_uid)">
        <template v-slot:rightSlot>
            <span class="txt_date">{{ itemData.sub_title }}</span>
        </template>
      </ListItem>
    </template>
    <div v-else class="area_empty">
      <div class="box_empty">
        <p class="desc_empty">{{ $poData.child_details_trans_list_empty }}</p>
      </div>
    </div>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import WalletHead from '@/components/common/walletHead/WalletHead';
import Button from '@/components/common/button/Button';
import CategoryStatistic from '@/components/common/categoryStatistic/CategoryStatistic';
import ButtonRouter from '@/components/common/button/ButtonRouter';
import BadgeNum from '@/components/common/badge/BadgeNum';
import ListItem from '@/components/common/list/ListItem';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import WalletMainChildViewModel from '@/views/v2/wallet/viewModel/WalletMainChildViewModel'

export default {
  name: 'WalletMainV2',
  mixins:[ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    WalletHead,
    Button,
    CategoryStatistic,
    ButtonRouter,
    BadgeNum,
    ListItem,
  },
  beforeMount(){
    this.has_return_btn = this.$route.query.has_return_btn && String(this.$route.query.has_return_btn) === 'true';
    this.viewModel.userType = 'CHILD';
    this.viewModel.init();

    // 상세다녀와서 closeRouteName 제거
    let currentQuery = JSON.parse(JSON.stringify(this.$route.query));
    currentQuery.closeRouteName = '';
    let newQuery = Object.fromEntries(Object.entries(Object.assign(currentQuery)).filter(([_, v]) => v != '' && v != undefined  && v != 'undefined' ));
    this.$router.replace({'query': newQuery}).catch(()=>{});
  },
  data(){
    return{
      viewModel: new WalletMainChildViewModel(),
      has_return_btn: false
    }
  },
  methods:{
    onClickBackKey(){
      if(this.viewModel.isMoneyInfoPopup){
        this.viewModel.onClickMoneyInfoClose();
      }
      else if(this.viewModel.isPointInfoPopup){
        this.viewModel.onClickPointInfoClose();
      }
      else{
        this.webEnd();
      }
    }
  }
}
</script>

<style scoped>
.area_top{padding:24px 20px 24px;background-color:#F3F1F1}
.btn_return{margin-top:16px}
.category_statistic{margin-top:24px}



.box_translist{overflow:hidden;margin-top:24px;border-radius:8px;background-color:#fff}
.box_translist .btn_router_transList >>> .tit_router{padding:20px 20px 12px;font-size:18px;line-height:28px}
.box_translist .btn_router_transList >>> .btn_router .icon_arrow_right{margin:22px 16px 0 0}
.box_translist .btn_router_transList >>> .btn_router .icon_arrow_right g{fill:#232323}
.box_translist .area_empty{padding:12px 20px 20px}
.box_translist .box_empty{padding:16px;border:1px dashed #A6A4A4;border-radius:4px;}
.box_translist .box_empty .desc_empty{font-weight:500;font-size:14px;line-height:22px;color:#999}
</style>