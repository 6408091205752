var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PageWithLayout',{attrs:{"bgColor":"#F3F1F1","isBackBtn":true,"isBackWebEnd":true,"isTitle":true,"title":_vm.$poData.child_wallet_main_title.format(_vm.viewModel.model.user.name),"isRightTextMenu":true,"rightTextMenu":_vm.$poData.child_wallet_main_setting,"isBodyFull":true,"leftRightPadding":20,"bottomPadding":48},on:{"onClickRightMenu":function($event){return _vm.pageMove('cWalletSet')}}},[_c('WalletHead',{attrs:{"title":_vm.$poData.child_wallet_main_balance,"amount":_vm.viewModel.model.wallet_amount,"money_amount":_vm.viewModel.model.money_amount,"point_amount":_vm.viewModel.model.point_amount},scopedSlots:_vm._u([{key:"inFold",fn:function(){return [(_vm.has_return_btn)?_c('Button',{staticClass:"btn_return",attrs:{"btnStyle":"primary","text":_vm.$poData.child_wallet_main_send_money_btn},on:{"onClickBtn":function($event){return _vm.pageMove('sendPocketMoneyReturn')}}}):_vm._e()]},proxy:true}])}),_c('CategoryStatistic',{attrs:{"paymentCategory":_vm.viewModel.model.paymentCategory,"routeName":"ChildTransListV2"}}),_c('div',{staticClass:"box_translist"},[_c('ButtonRouter',{staticClass:"btn_router_transList",attrs:{"text":_vm.$poData.child_details_heading_2,"isArrowRight":_vm.viewModel.model.has_transaction,"routeName":_vm.viewModel.model.has_transaction ? 'ChildTransListV2' : '',"routeQuery":{ has_return_btn : _vm.has_return_btn }}}),(_vm.viewModel.model.has_transaction)?_vm._l((_vm.viewModel.model.wallet_transaction_list),function(itemData,index){return _c('ListItem',{key:`transItem${index}`,attrs:{"padding":17,"thumbNail":{
        size: 40,
        url : itemData.profile_image_url
      },"title":itemData.title,"leftSubText":itemData.message || itemData.memo,"leftSubTextColor":itemData.message ? 'primary' : 'gray',"leftBadge":{
        value : itemData.label_display_name,
        type : 'sub1'
      },"rightSubText":_vm._f("convertNumberToComma")(itemData.amount,false,'0','원'),"isRightSubTextColor":itemData.amount >= 0 ? 'red' : ''},on:{"onClickItem":function($event){return _vm.viewModel.onClickDetail(itemData.transaction_uid)}},scopedSlots:_vm._u([{key:"rightSlot",fn:function(){return [_c('span',{staticClass:"txt_date"},[_vm._v(_vm._s(itemData.sub_title))])]},proxy:true}],null,true)})}):_c('div',{staticClass:"area_empty"},[_c('div',{staticClass:"box_empty"},[_c('p',{staticClass:"desc_empty"},[_vm._v(_vm._s(_vm.$poData.child_details_trans_list_empty))])])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }